<template>
  <a
    v-if="data"
    class="home-item-btn column is-6-mobile is-3-tablet has-text-centered has-text-weight-bold has-text-black"
    :title="data.label"
    @click.prevent="goToRoute(data)"
  >
    <img
      :src="data.icon"
      :alt="data.label"
    >
    {{ data.label }}
  </a>
</template>

<script>
export default {
  name: "HomeListItem",
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  methods: {
    async goToRoute(item) {
      if (item?.route) {
        try {
          const routeConfig = {name: item.route, params: item?.params}

          if (item.requiredSession && !this.isAuth) {
            let to = this.$router.resolve(routeConfig);
            let targetHref = to.href
            if(targetHref === '/profile'){
              targetHref = '/'
            }

            await this.$router.push(`/login?redirect_to=${targetHref}`)
            this.open = false
            return
          }

          await this.$router.push(routeConfig)
        } catch (e) {
          console.error('No se ha podido ir a la ruta')
          console.error(e)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
