<template>
  <div class="page container home p-4">
    <div class="columns is-multiline">
      <div class="column is-12-mobile">
        <search-bar />
      </div>
    </div>
    <div
      v-if="hasSearchResults"
      class="columns is-multiline is-flex-direction-column is-flex"
    >
      <h4 class="is-mobile-12 column mb-2 has-text-weight-semibold">
        {{ searchResults.length }} {{ resultsPluralized }}
        buscando por "{{ searchQuery }}"
      </h4>
      <search-result-list-item
        v-for="item in searchResults"
        :key="item.id"
        :data="item"
      />
    </div>
    <div
      v-if="!hasSearchResults"
      class="columns is-multiline is-flex-direction-row is-flex"
    >
      <home-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        :data="item"
      />
    </div>
    <birthday-modal
      :show="showBirthdayModal"
      :value="birthdayName"
      @close="closeBirthdayModal"
    />
  </div>
</template>

<script>

import SearchResultListItem from "@/components/lists/SearchResultListItem";
import SearchBar from "@/components/SearchBar";
import HomeListItem from "@/components/lists/HomeListItem";
import BirthdayModal from "@/components/modals/BirthdayModal.vue";

export default {
  title: 'Página principal',
  name: 'Home',
  components: {
    BirthdayModal,
    HomeListItem,
    SearchBar,
    SearchResultListItem
  },
  data() {
    return {
      birthdayName: '',
      showBirthdayModal: false
    }
  },
  computed: {
    menuItems() {
      return this.$store.state.menuItems.filter((item) => item.showInHome === true)
    },
    hasSearchResults() {
      return this.$store.getters['hasStoredSearch']
    },
    searchQuery() {
      return this.$store.state.searchQuery
    },
    searchResults() {
      return this.$store.state.searchResults
    },
    resultsPluralized() {
      if(this.searchResults === 1){
        return 'resultado'
      }else{
        return 'resultados'
      }
    }
  },
  mounted() {
    const query = this.$route.query

    if(query.hasOwnProperty('happyBirthday')){
      this.birthdayName = query.happyBirthday
      this.showBirthdayModal = true
    }
  },
  methods: {
    closeBirthdayModal(){
      this.showBirthdayModal = false
    }
  }
}
</script>
