<template>
  <div
    class="search-result-item separator has-background-white py-2 px-5"
    @click="handleClick"
  >
    <img
      :src="getImagePath(data)"
      :alt="data.title"
      class="mt-1 thumb"
      @error="imageError"
    >
    <div class="is-size-7">
      <b-tag
        :type="getTagColor(data.class)"
        class="my-1"
      >
        {{ getTagLabel(data.class) }}
      </b-tag>
      <h4 class="has-text-weight-bold mb-2">
        {{ data.title }}
      </h4>
    </div>
  </div>
</template>

<script>
import ImageUtils from '@/utils/image'

export default {
  name: "SearchResultListItem",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      showDescription: false,
      maxDescLen: 90,
      isValid: true,
      LEVELS: {
        'rojo': 'red-bg',
        'naranja': 'orange-bg',
        'azul': 'blue-bg',
        'verde': 'green-bg'
      }
    }
  },
  computed: {
    levelClass() {
      if (!this.data ||
        !this.data.hasOwnProperty('level') ||
        !this.LEVELS.hasOwnProperty(this.data.level)
      ) {
        return ''
      }

      return this.LEVELS[this.data.level]
    },
    hasLevel() {
      return this.levelClass !== ''
    },
    isExternalTarget() {
      return this.data.hasOwnProperty('is_external') ? this.data.is_external : false
    },
    hasMoreDesc() {

      return this.data.description.length > this.maxDescLen
    }
  },
  methods: {
    getTagLabel(className) {
      if (className === 'Album') {
        return 'Album'
      }

      if (className === 'GalleryVideo') {
        return 'Galería de vídeo'
      }

      if (className === 'Media') {
        return 'Interactivo'
      }

      if (className === 'Article') {
        return 'Artículo'
      }

      if (className === 'School') {
        return 'Escuela'
      }

      if (className === 'Presentation') {
        return 'Presentación'
      }

      if (className === 'Task') {
        return 'Tarea'
      }

      if (className === 'Score') {
        return 'Partitura'
      }

      return className
    },
    getTagColor(className) {
      if (className === 'Album') {
        return 'is-primary'
      }

      if (className === 'GalleryVideo') {
        return 'is-dark'
      }

      if (className === 'Media') {
        return 'is-danger'
      }

      if (className === 'Article') {
        return 'is-success'
      }

      if (className === 'School') {
        return 'is-warning'
      }

      if (className === 'Presentation') {
        return 'is-info'
      }

      if (className === 'Task') {
        return 'is-info'
      }

      return 'is-default'
    },
    getImagePath(item) {
      return ImageUtils.getCDNFullPath(item.image)
    },
    imageError() {
      this.isValid = false;
    },
    async handleClick() {
      const className = this.data.class
      const itemId = this.data.id
      const itemSlug = this.data.slug

      if (className === 'Album') {
        await this.$router.push({name: 'albums-detail', params: {id: itemId}})
      }

      if (className === 'GalleryVideo') {
        await this.$router.push({name: 'videos-gallery', params: {galleryId: itemId}})
      }

      if (className === 'Media') {
        await this.$router.push({name: 'media-detail', params: {id: itemId}})
      }

      if (className === 'Article') {
        await this.$router.push({name: 'articles-detail', params: {id: itemId}})
      }

      if (className === 'School') {
        await this.$router.push({name: 'school-detail', params: {id: itemId}})
      }

      if (className === 'Presentation') {
        await this.$router.push({name: 'presentations-detail', params: {id: itemId}})
      }

      if (className === 'Task') {
        await this.$router.push({name: 'tasks-detail', params: {slug: itemSlug}})
      }
    }
  }
}
</script>
