<template>
  <div>
    <b-field
      :message="searchMsg"
      :type="searchMsg !== ''?'is-danger':''"
    >
      <b-input
        v-model.trim="query"
        placeholder="Buscar..."
        icon="search"
        icon-clickable
        icon-right-clickable
        :icon-right="rightIcon"
        @keyup.native.enter="doSearch"
        @icon-click="doSearch"
        @icon-right-click="clearSearch"
      />
    </b-field>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      searchMsg: '',
      searched: false,
      searchDelay: 1000,
      searchTO: null,
      query: '',
      results: null
    }
  },
  computed: {
    hasSearchResults() {
      return !!(this.results && this.results.length > 0)

    },
    rightIcon() {
      if (this.query.length > 0) {
        return 'times-circle'
      }

      return null
    }
  },
  beforeMount() {
    this.query = this.$store.state.searchQuery
    this.searched = this.query !== ''
    this.results = this.$store.state.searchResults
  },
  methods: {
    clearSearch() {
      this.query = ''
      this.results = []
      this.searchMsg = ''
      this.$store.commit('setSearchQuery', this.query)
      this.$store.commit('setSearchResults', this.results)
    },
    async doSearch() {
      if (!this.query) {
        this.searchMsg = 'Escribe algo para comenzar a buscar'
        return
      }
      this.searched = false
      try {
        this.results = await this.$api.search.search(this.query)
        this.searched = true

        this.$store.commit('setSearchQuery', this.query)
        this.$store.commit('setSearchResults', this.results)

        if (this.results.length === 0) this.searchMsg = 'Sin resultados'
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>
