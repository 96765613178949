<template>
  <b-modal
    v-model="showModal"
    class="birthday-modal"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Modal vídeo"
    aria-modal
    @close="close"
  >
    <template>
      <div
        class="modal-card"
        style="width: auto"
      >
        <section
          class="modal-card-body"
        >
          <h4 class="title mb-2">
            <span>¡Felicidades {{ userName }}!</span>
          </h4>
          <youtube
            ref="youtube"
            :video-id="videoId"
            :fit-parent="true"
            @paused="initInterval"
            @ended="initInterval"
            @playing="clearInterval"
          />
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Cerrar"
            @click="close"
          />
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import JSConfetti from 'js-confetti'

const jsConfetti = new JSConfetti()
export default {
  name: "BirthdayModal",
  props: {
    show: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      audio: null,
      userName: this.value,
      videoId: 'fVkqwwWIry8',
      showModal: this.show,
      interval: null
    }
  },
  computed: {
    hasVideo() {
      return this.videoId !== ''
    }
  },
  watch: {
    value: function (val) {
      this.userName = val
    },
    show: function (val) {
      this.showModal = val
    },
    showModal: function (val) {
      if (!val) {
        this.clearInterval()
        try {
          this.$refs.youtube.player.stopVideo()
        } catch (e) {
          console.error("No se ha podido parar el vídeo")
        }
      } else {
        this.addConfetti()
        this.initInterval()
      }
    }
  },
  mounted() {
    try {
      this.audio = new Audio('/audio/confetti-effect.mp3')
    } catch (e) {
      console.error('error loading confetti-effect')
    }
    if (this.show) {
      this.addConfetti()
      this.initInterval()
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    initInterval() {
      if (this.interval) {
        return
      }
      console.log('init int')
      this.interval = setInterval(this.addConfetti, 5000)
    },
    clearInterval() {
      if (!this.interval) {
        return
      }
      console.log('clear int')

      clearInterval(this.interval)
      this.interval = null
    },
    addConfetti() {
      try {
        if (!this.audio) {
          return
        }
        this.audio.play()

      } catch (e) {
        console.error("cant play confetti audio")
      }

      try{
        jsConfetti.addConfetti()
      }catch (e) {
        console.error("cant launching confetti")
      }
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 500px) {
  .birthday-modal iframe {
    width: 100%;
    max-width: 640px;
  }
}

</style>
